import React, { useState } from 'react'
import { graphql } from 'gatsby'
import Builder from '../components/Builder/Builder'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import { ButtonGroup, ImageFrame, Typography } from '../components/utils'
import { QuickBuilderForm } from '../components/Builder/QuickBuilderForm'
// import { ProBuilderForm } from '../components/Builder/ProBuilderForm'
import { FormInfoCard } from '../components/Builder/FormInfoCard'
import { Loader } from '../components/Loader/Loader'
import { Info } from 'react-feather'

export default function BuilderPage({ data }) {
  const builderHelp = data.builderHelp.builderHelpText
  const imageData = data.builderAd

  const [isLoadingScreen, setLoadingScreen] = useState(false)
  const [builderType, setBuilderType] = useState('quickBuilder')

  return isLoadingScreen ? (
    <Loader />
  ) : (
    <Wrapper>
      <ContentWrapper>
        <Hero>
          <div>
            <Typography variant="h1">Builder</Typography>
            <Disclaimer>
              <span>
                <Info size={16} color="rgba(255,255,255,0.7)" />
              </span>
              ghostbuilder stellt nur eine Empfehlung zusammen.
            </Disclaimer>
          </div>
        </Hero>
        <Container builderType={builderType}>
          <QuickBuilderForm setLoadingScreen={setLoadingScreen} />
          <FormInfoCard
            data={builderHelp}
            className="formInfoCard"
            builderType={builderType}
          />
        </Container>
      </ContentWrapper>
    </Wrapper>
  )
}

const Hero = styled.div`
  margin: 1.5rem 1rem 0;
  h1,
  p {
    justify-content: center;
  }
  @media (min-width: 768px) {
    margin: 2rem 1.5rem 0;
    h1,
    p {
      justify-content: start;
    }
  }
  @media (min-width: 1100px) {
    margin: 2rem 0 0;
  }
  @media (min-width: 1280px) {
    margin: 2rem 0 0;
  }
`

export const builderHelpQuery = graphql`
  {
    builderHelp: sanityBuilderHelp {
      builderHelpText {
        heading
        helperText {
          children {
            text
            _type
          }
          style
          _type
        }
        isQuickBuilder
        isVisible
        _key
      }
    }
    builderAd: sanityAdBannerSettings(domainAppearence: { eq: "build" }) {
      link
      desktop: desktop {
        alt
        asset {
          gatsbyImageData
        }
      }
      mobile: mobile {
        alt
        asset {
          gatsbyImageData
        }
      }
    }
  }
`

const Wrapper = styled(motion.div)`
  min-height: 100vh;
  height: 100%;
  width: 100%;
  position: relative;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-block: 5rem 5rem;

  background: linear-gradient(
      112.97deg,
      #0e99d4 5.81%,
      rgba(14, 153, 212, 0) 38.8%
    ),
    radial-gradient(
      29.76% 49.52% at 88.92% 92.72%,
      #ffb659 11.46%,
      rgba(232, 122, 94, 0) 100%
    ),
    linear-gradient(180deg, #4b368b 0%, #e8619c 100%);

  @media (min-width: 768px) {
    padding-top: 80px;
  }

  @media (min-width: 960px) {
    background: radial-gradient(
        21.45% 42.43% at 29.93% 43.99%,
        #583fa0 0%,
        rgba(115, 89, 191, 0) 100%
      ),
      linear-gradient(112.97deg, #0e99d4 5.81%, rgba(14, 153, 212, 0) 38.8%),
      radial-gradient(
        29.76% 49.52% at 88.92% 92.72%,
        #ffb659 11.46%,
        rgba(232, 122, 94, 0) 100%
      ),
      linear-gradient(180deg, #4b368b 0%, #e8619c 100%);
  }
`

const ContentWrapper = styled.div`
  max-width: 1024px;
  margin: 0 auto;
`

const ProBackground = styled(motion.div)`
  z-index: 1;
  opacity: 0;
  visibility: hidden;

  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  background: radial-gradient(
      75.17% 64.09% at 94.58% 104.97%,
      rgba(226, 53, 128, 0.79) 0%,
      rgba(78, 33, 52, 0) 100%
    ),
    radial-gradient(
      41.96% 50.66% at 27.88% 33.85%,
      #4b368b 0%,
      rgba(75, 54, 139, 0) 100%,
      rgba(28, 20, 51, 0) 100%
    ),
    linear-gradient(113.93deg, #4658ff 2.54%, rgba(70, 88, 255, 0) 35.48%),
    #140e25;
`

const Container = styled.div`
  z-index: 2;
  position: relative;
  margin: 1rem 0.5rem 3rem;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr;
  align-content: flex-start;
  align-items: start;
  justify-items: center;
  /* align-content: flex-start; */
  row-gap: 2rem;
  background-color: rgba(255, 255, 255, 0.2);
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-radius: 12px;

  padding: 8px;

  .formInfoCard {
    grid-row: 2;
  }
  @media (min-width: 768px) {
    margin: 2rem 1rem 0;
    grid-template-columns: 1fr 1fr;
    .formInfoCard {
      padding: 24px;
      align-self: start;
      grid-column: 2;
      grid-row: 1;
    }
  }
  @media (min-width: 1100px) {
    margin: 2rem 0rem 3rem;
    /* padding: 0 2rem; */
    grid-template-columns: ${({ builderType }) =>
      builderType === 'quickBuilder' ? '4fr 3fr' : '1fr'};
    column-gap: 1rem;
    /* margin-top: 8rem; */
    .formInfoCard {
      grid-row: 1;
    }
  }
`

const SwitchContainer = styled.div`
  grid-column: 1;
  grid-row: 1;
  width: 100%;
  max-width: 672px;
  @media (min-width: 1280px) {
    grid-column: ${({ builderType }) =>
      builderType === 'quickBuilder' ? '1 / 3' : '1'};
  }
`

const Banner = styled(ImageFrame)`
  margin: 1.5rem auto 0;
  @media (min-width: 768px) {
    margin: 2rem 1rem 0;
    /* padding-inline: 1rem; */
    padding-inline: 0;
  }
  @media (min-width: 1100px) {
    margin-inline: 0;
  }
`

const Disclaimer = styled(Typography)`
  margin: 4px auto;
  font-size: 0.8rem;
  font-weight: 550;
  letter-spacing: 0.25px;
  color: rgba(255, 255, 255, 0.7);
  span {
    padding-right: 5px;
    svg {
      transform: translateY(3px);
    }
  }
`

/*
return isLoadingScreen ? (
  <Loader />
) : (
  <Wrapper>
    <ProBackground
        initial={{ opacity: 0, visibility: 'hidden' }}
        animate={{
          opacity: builderType === 'quickBuilder' ? 0 : 1,
          visibility: builderType === 'quickBuilder' ? 'hidden' : 'visible',
        }}
        transition={{
          duration: 0,
          ease: 'easeOut',
        }}
      />
    <Container builderType={builderType}>
      <Banner /> 
      {imageData.link && <Banner imageData={imageData} />}

      <SwitchContainer builderType={builderType}>
          <ButtonGroup
            labelId="BtnGroupLarge-Builder-Type"
            onChange={(e) => setBuilderType(e.target.value)}
            value={builderType}
            defaultOption={builderType}
            options={[
              {
                label: 'Quick Builder',
                value: 'quickBuilder',
              },
              {
                label: 'Pro Builder',
                value: 'proBuilder',
              },
            ]}
            name="builder-type"
          />
        </SwitchContainer>
      <QuickBuilderForm setLoadingScreen={setLoadingScreen} />
      <FormInfoCard data={builderHelp} className="formInfoCard" />
      {builderType === 'quickBuilder' ? (
          <>
            <QuickBuilderForm setLoadingScreen={setLoadingScreen} />
            <FormInfoCard data={builderHelp} className="formInfoCard" />
          </>
        ) : (
          <ProBuilderForm setLoadingScreen={setLoadingScreen} />
        )} 
    </Container>
  </Wrapper>
)
*/
