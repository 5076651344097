import React, { useEffect, useState } from 'react'
import { navigate } from 'gatsby'
import styled from 'styled-components'
import Cookies from 'universal-cookie'

import { FormSlider } from './FormSlider'
import { FormLabel } from './FormLabel'

import {
  callBuild,
  loadBuilderCookies,
  setBuilderCookie,
  useBudget,
  useProviders,
  useQuickBuilderState,
} from '../helpers'

import { Button, Checkbox, Dropdown } from '../utils'

// import { budgetData } from './BuilderData/mainData'
// import * as processorData from './BuilderData/processorData'
// import * as graphicData from './BuilderData/graphicscardData'
import useMedia from 'use-media'

const cookie = new Cookies()

export function QuickBuilderForm({ setLoadingScreen }) {
  const isDesktop = useMedia({ minWidth: 768 })
  const [state, dispatch] = useQuickBuilderState()
  const [providers] = useProviders()
  const [budgetData] = useBudget()

  const [dynamicBudget, setDynamicBudget] = useState(
    budgetData.filter((item) =>
      state.isPlaceholder ? item.isPlaceholder : item.isNormal
    )
  )

  useEffect(() => {
    const cookieState = cookie.get('quickbuilderData')
    if (cookie.get('isCookieAccepted') && cookieState) {
      loadBuilderCookies(cookieState, dispatch)
    }
  }, [dispatch])

  useEffect(() => {
    setDynamicBudget(
      budgetData.filter((item) =>
        state.isPlaceholder ? item.isPlaceholder : item.isNormal
      )
    )
  }, [budgetData])

  useEffect(() => {
    if (state.isPlaceholder) {
      const placeholderBudget = budgetData.filter((item) => item.isPlaceholder)
      setDynamicBudget(placeholderBudget)
    } else {
      const normalBudget = budgetData.filter((item) => item.isNormal)
      setDynamicBudget(normalBudget)
    }
  }, [state.isPlaceholder, dispatch])

  async function handleSubmit(e) {
    e.preventDefault()
    setBuilderCookie(state)
    setLoadingScreen(true)
    if (state.budget === '400' || (state.budget === '450' && state.isCase)) {
      return navigate('/low-budget/')
    }
    const options = {
      budget: state.budget.value,
      // cpu: state.cpu,
      cpu: 'auto',
      gpu: 'auto',
      isCase: state.isCase,
      isPlaceholder: state.isPlaceholder,
      provider: state.provider.value,
    }

    fetchBuild(options)
  }

  async function fetchBuild(options, needsNewBudget = false, n = 0) {
    let newOptions = options
    if (needsNewBudget) {
      const currentBudgetIndex = dynamicBudget.findIndex(
        (item) => item.value === options.budget
      )
      // console.log('currentBudgetIndex :', currentBudgetIndex)
      if (currentBudgetIndex - 1 >= 0) {
        const newBudget = dynamicBudget[currentBudgetIndex - 1].value
        newOptions = { ...options, budget: newBudget }
      } else {
        navigate('/something-went-wrong')
        return
      }
    }

    try {
      const { build, buildMetadata } = await callBuild(newOptions)
      navigate(`/build/${buildMetadata.buildID}`, {
        state: {
          buildMetadata: buildMetadata,
          buildData: build,
          isNewConfig: n > 0,
          ...state,
        },
      })
    } catch (error) {
      n++
      if (n < 4) {
        fetchBuild(newOptions, true, n)
      } else {
        console.error(error.message)
        navigate('/something-went-wrong')
      }
    }
  }

  return (
    <Form
      className="quickBuilderForm"
      onSubmit={handleSubmit}
      aria-label="form"
      noValidate
    >
      <Cell>
        {dynamicBudget && (
          <FormSlider
            name="quickbuilder-budget"
            description="Wie viel soll investiert werden?"
            dispatch={dispatch}
            dispatchType="budget"
            value={state.budget}
            // defaultValue={budgetData.map((e) => e.value).indexOf(800)}
            defaultValue={dynamicBudget
              .map((e) => e.value)
              .indexOf(state.budget.value)}
            datalist={dynamicBudget}
            min={0}
            max={dynamicBudget.length - 1}
            style={{ gridColumn: 'span 2' }}
            key={dynamicBudget.length}
          />
        )}
      </Cell>
      <Cell columns="1" style={{ gridColumn: isDesktop ? 1 : 'span 2' }}>
        <FormLabel htmlFor="provider" gridPos="unset">
          Bevorzugter Anbieter
        </FormLabel>
        <Dropdown
          id="provider"
          value={state.provider}
          options={providers}
          onChange={(value) => dispatch({ type: 'provider', value: value })}
          placeholder="Anbieter..."
        />
      </Cell>
      <Cell
        columns="1"
        justifyContent="start"
        style={{
          gridColumn: isDesktop ? 2 : 'span 2',
          justifyContent: isDesktop ? 'end' : 'start',
          display: 'flex',
        }}
      >
        <Checkbox
          checked={state.isPlaceholder}
          onChange={({ target }) =>
            dispatch({ type: 'placeholder', value: target.checked })
          }
          name="checkbox-placeholder"
          description="Mit Platzhalter"
        />
      </Cell>
      <Cell columns="1" justifyContent="center">
        <Button
          size="medium"
          type="submit"
          value="Submit"
          fullWidth
          whileTap={{ scale: 0.96 }}
          whileHover={{ scale: 1.04 }}
        >
          Computer erstellen
        </Button>
      </Cell>
    </Form>
  )
}

const Form = styled.form`
  padding: 24px 14px 16px;
  height: 100%;
  width: 100%;
  display: grid;
  justify-content: center;
  align-content: center;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 1rem;
  row-gap: 1.5rem;

  border-radius: 10px;
  background: radial-gradient(
    101.41% 100% at 50% 0%,
    rgba(224, 218, 241, 0.3) 0%,
    rgba(255, 255, 255, 0.15) 61.46%
  );
  border: 2px solid rgba(255, 255, 255, 0.1);
  box-shadow: 16px 16px 50px 0 rgb(0 0 0 / 10%);

  h1 {
    margin-bottom: 1rem;
  }

  @media (min-width: 768px) {
    padding: 2rem;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 2rem;
  }
  @media (min-width: 960px) {
    row-gap: 2.5rem;
  }

  @media (min-width: 1280px) {
    justify-self: end;
  }
`

const Cell = styled.div`
  grid-column: span 2;
  display: grid;
  grid-template-columns: ${({ columns }) =>
    `repeat(${columns ? columns : 1}, 1fr)`};
  gap: ${({ gridGap }) => (gridGap ? `${gridGap}` : 0)};
  column-gap: ${({ columnGap }) => (columnGap ? `${columnGap}` : 0)};
  row-gap: ${({ rowGap }) => (rowGap ? `${rowGap}` : 0)};
  justify-content: ${({ justifyContent }) =>
    justifyContent ? `${justifyContent}` : 'start'};

  @media (min-width: 768px) {
    /* margin-bottom: 1.5rem; */
  }
`
