import React, { useEffect } from 'react'
import styled from 'styled-components'
import { Info } from 'react-feather'

import { FormLabel } from './FormLabel'

import {
  useProCpuState,
  useProGpuState,
  useProMainState,
  useProMainboardState,
  useProRamState,
  useProStorageState,
  useProPowerState,
  useProCoolerState,
  useProCaseState,
} from '../helpers'
import { Button, ButtonGroup, Typography, ToggleButton } from '../utils'
import {
  CaseOptions,
  CoolerOptions,
  GraphicscardOptions,
  MainOptions,
  MainboardOptions,
  PowerSupplyOptions,
  ProcessorOptions,
  RamOptions,
  StorageOptions,
} from './ProBuilderOptions'

import * as mainData from './BuilderData/mainData'
import * as processorData from './BuilderData/processorData'
import * as graphicData from './BuilderData/graphicscardData'
import * as mainboardData from './BuilderData/mainboardData'
import * as ramData from './BuilderData/ramData'
import * as storageData from './BuilderData/storageData'
import * as powerData from './BuilderData/powerData'
import * as coolerData from './BuilderData/coolerData'
import * as caseData from './BuilderData/caseData'

export function ProBuilderForm({ handleSubmit }) {
  const [mainState, mainDispatch] = useProMainState()
  const [processorState, processorDispatch] = useProCpuState()
  const [graphicState, graphicDispatch] = useProGpuState()
  const [mainboardState, mainboardDispatch] = useProMainboardState()
  const [ramState, ramDispatch] = useProRamState()
  const [storageState, storageDispatch] = useProStorageState()
  const [powerState, powerDispatch] = useProPowerState()
  const [coolerState, coolerDispatch] = useProCoolerState()
  const [caseState, caseDispatch] = useProCaseState()

  useEffect(() => {
    if (mainState.mainToggle) {
      processorDispatch({ type: 'cpu-toggle', value: true })

      setTimeout(() => {
        graphicDispatch({ type: 'gpu-toggle', value: true })
      }, 50)
      setTimeout(() => {
        mainboardDispatch({ type: 'mainboard-toggle', value: true })
      }, 100)
      setTimeout(() => {
        ramDispatch({ type: 'ram-toggle', value: true })
      }, 200)
      setTimeout(() => {
        storageDispatch({ type: 'storage-toggle', value: true })
      }, 300)
      setTimeout(() => {
        powerDispatch({ type: 'power-toggle', value: true })
      }, 400)
      setTimeout(() => {
        coolerDispatch({ type: 'cooler-toggle', value: true })
      }, 500)
      setTimeout(() => {
        caseDispatch({ type: 'case-toggle', value: true })
      }, 600)
    } else {
      processorDispatch({ type: 'cpu-toggle', value: false })
      graphicDispatch({ type: 'gpu-toggle', value: false })
      mainboardDispatch({ type: 'mainboard-toggle', value: false })
      ramDispatch({ type: 'ram-toggle', value: false })
      storageDispatch({ type: 'storage-toggle', value: false })
      powerDispatch({ type: 'power-toggle', value: false })
      coolerDispatch({ type: 'cooler-toggle', value: false })
      caseDispatch({ type: 'case-toggle', value: false })
    }
  }, [
    mainState.mainToggle,
    processorDispatch,
    graphicDispatch,
    mainboardDispatch,
    ramDispatch,
    storageDispatch,
    powerDispatch,
    coolerDispatch,
    caseDispatch,
  ])

  return (
    <Container
      className="proBuilderForm"
      onSubmit={handleSubmit}
      aria-label="form"
      noValidate
    >
      <Cell columns="2" alignItems="center" fullLength>
        <Typography variant="h1" style={{ gridColumn: 1 }}>
          Pro Builder
        </Typography>
        <ToggleButton
          style={{ justifySelf: 'end' }}
          defaultChecked={mainState.mainToggle}
          dispatch={mainDispatch}
          dispatchType="main-toggle"
        />
      </Cell>

      <Cell columns="2" rowGap="16px" columnGap="32px" marginBottom="48px">
        <MainOptions
          valueData={mainState}
          optionsData={mainData}
          dispatch={mainDispatch}
        />
      </Cell>

      <Cell columns="2" rowGap="16px" columnGap="32px" marginBottom="48px">
        <CellHeading
          defaultChecked={processorState.cpuToggle}
          dispatch={processorDispatch}
          dispatchType="cpu-toggle"
        >
          Prozessor
        </CellHeading>
        <ButtonGroup
          labelId="BtnGroupLarge-Cpu"
          onChange={({ target }) =>
            processorDispatch({ type: 'cpu-type', value: target.value })
          }
          value={processorState.typeValue}
          defaultOption={processorState.typeValue}
          options={processorData.typeData}
          name="pro-cpu-type"
          gridColumn="span 2"
        />

        {(mainState.mainToggle || processorState.cpuToggle) && (
          <ProcessorOptions
            valueData={processorState}
            optionsData={processorData}
            dispatch={processorDispatch}
          />
        )}
      </Cell>

      <Cell columns="2" rowGap="16px" columnGap="32px" marginBottom="48px">
        <CellHeading
          defaultChecked={graphicState.gpuToggle}
          dispatch={graphicDispatch}
          dispatchType="gpu-toggle"
        >
          Grafikkarte
        </CellHeading>
        <ButtonGroup
          labelId="BtnGroupLarge-Gpu"
          onChange={({ target }) =>
            graphicDispatch({ type: 'gpu-type', value: target.value })
          }
          value={graphicState.typeValue}
          defaultOption={graphicState.typeValue}
          options={graphicData.typeData}
          name="gpu-type"
          gridColumn="span 2"
        />
        {(mainState.mainToggle || graphicState.gpuToggle) && (
          <GraphicscardOptions
            valueData={graphicState}
            optionsData={graphicData}
            dispatch={graphicDispatch}
          />
        )}
      </Cell>

      <Cell columns="2" rowGap="16px" columnGap="32px" marginBottom="48px">
        <CellHeading
          defaultChecked={mainboardState.mainboardToggle}
          dispatch={mainboardDispatch}
          dispatchType="mainboard-toggle"
        >
          Mainboard
        </CellHeading>
        {(mainState.mainToggle || mainboardState.mainboardToggle) && (
          <MainboardOptions
            cpuValue={processorState.typeValue}
            valueData={mainboardState}
            optionsData={mainboardData}
            dispatch={mainboardDispatch}
          />
        )}
      </Cell>

      <Cell columns="2" rowGap="16px" columnGap="32px" marginBottom="48px">
        <CellHeading
          defaultChecked={ramState.ramToggle}
          dispatch={ramDispatch}
          dispatchType="ram-toggle"
        >
          RAM
        </CellHeading>
        {(mainState.mainToggle || ramState.ramToggle) && (
          <RamOptions
            valueData={ramState}
            optionsData={ramData}
            dispatch={ramDispatch}
          />
        )}
      </Cell>

      <Cell columns="2" rowGap="16px" columnGap="32px" marginBottom="48px">
        <CellHeading
          defaultChecked={storageState.storageToggle}
          dispatch={storageDispatch}
          dispatchType="storage-toggle"
        >
          Speicher
        </CellHeading>
        {(mainState.mainToggle || storageState.storageToggle) && (
          <StorageOptions
            valueData={storageState}
            optionsData={storageData}
            dispatch={storageDispatch}
          />
        )}
      </Cell>
      <Cell columns="2" rowGap="16px" columnGap="32px" marginBottom="48px">
        <CellHeading
          defaultChecked={powerState.powerToggle}
          dispatch={powerDispatch}
          dispatchType="power-toggle"
        >
          Netzteil
        </CellHeading>
        {(mainState.mainToggle || powerState.powerToggle) && (
          <PowerSupplyOptions
            valueData={powerState}
            optionsData={powerData}
            dispatch={powerDispatch}
          />
        )}
      </Cell>
      <Cell columns="2" rowGap="16px" columnGap="32px" marginBottom="48px">
        <CellHeading
          defaultChecked={coolerState.coolerToggle}
          dispatch={coolerDispatch}
          dispatchType="cooler-toggle"
        >
          Kühler
        </CellHeading>
        {(mainState.mainToggle || coolerState.coolerToggle) && (
          <CoolerOptions
            valueData={coolerState}
            optionsData={coolerData}
            dispatch={coolerDispatch}
          />
        )}
      </Cell>

      <Cell columns="2" rowGap="16px" columnGap="32px" marginBottom="48px">
        <CellHeading
          defaultChecked={caseState.caseToggle}
          dispatch={caseDispatch}
          dispatchType="case-toggle"
        >
          Gehäuse
        </CellHeading>
        {(mainState.mainToggle || caseState.caseToggle) && (
          <CaseOptions
            valueData={caseState}
            optionsData={caseData}
            dispatch={caseDispatch}
          />
        )}
      </Cell>
      <Cell columns="0" justifyContent="center">
        <Button
          size="medium"
          type="submit"
          value="Submit"
          whileTap={{ scale: 0.96 }}
          whileHover={{ scale: 1.04 }}
        >
          Computer erstellen
        </Button>
      </Cell>
      <Disclaimer>
        <span>
          <Info size={16} color="rgba(255,255,255,0.7)" />
        </span>
        ghostbuilder stellt nur eine Empfehlung zusammen.
      </Disclaimer>
    </Container>
  )
}

const CellHeading = ({ children, defaultChecked, dispatch, dispatchType }) => (
  <Cell
    columns="2"
    gridColumn="span 2"
    alignItems="center"
    fullLength
    marginBottom="-8px"
  >
    <FormLabel
      htmlFor="BtnGroupLarge-cooler"
      aria-label="gridcell"
      isLarge
      style={{ gridColumn: 1 }}
    >
      {children}
    </FormLabel>
    <ToggleButton
      style={{ justifySelf: 'end' }}
      defaultChecked={defaultChecked}
      dispatch={dispatch}
      dispatchType={dispatchType}
    />
  </Cell>
)

const Container = styled.form`
  padding: 1rem;
  height: fit-content;
  width: 100%;
  max-width: 672px;
  display: grid;
  justify-content: center;
  align-content: center;
  grid-template-columns: repeat(4, minmax(4rem, 5rem));
  column-gap: 1rem;

  border-radius: 16px;
  background: linear-gradient(
    135deg,
    rgba(255, 255, 255, 0.15),
    rgba(255, 255, 255, 0.25)
  );
  border: 2px solid rgba(255, 255, 255, 0.1);
  box-shadow: 16px 16px 50px 0 rgb(0 0 0 / 10%);

  h1 {
    /* margin-bottom: 1rem; */
  }

  @media (min-width: 768px) {
    padding: 3rem 3.5rem;
    grid-template-columns: repeat(4, minmax(6rem, 1fr));
  }
`

const Cell = styled.div`
  grid-column: ${({ gridColumn }) => (gridColumn ? gridColumn : 'span 4')};
  margin-bottom: ${({ marginBottom }) =>
    marginBottom ? `${marginBottom}` : '1.25rem'};
  width: ${({ fullLength }) => (fullLength ? '100%' : 'initial')};

  display: grid;
  grid-auto-flow: row;
  grid-template-columns: ${({ columns }) =>
    `repeat(${columns ? columns : 1}, 1fr)`};
  gap: ${({ gridGap }) => (gridGap ? `${gridGap}` : 0)};
  column-gap: ${({ columnGap }) => (columnGap ? `${columnGap}` : 0)};
  row-gap: ${({ rowGap }) => (rowGap ? `${rowGap}` : 0)};
  justify-content: ${({ justifyContent }) =>
    justifyContent ? `${justifyContent}` : 'start'};
  align-items: ${({ alignItems }) =>
    alignItems ? `${alignItems}` : 'initial'};

  @media (min-width: 768px) {
    margin-bottom: ${({ marginBottom }) =>
      marginBottom ? `${marginBottom}` : '1.5rem'};
  }
`

const Disclaimer = styled(Typography)`
  grid-column: span 4;
  margin: 0 auto;
  font-size: 0.8rem;
  font-weight: 550;
  letter-spacing: 0.25px;
  color: rgba(255, 255, 255, 0.7);
  span {
    padding-right: 5px;
    svg {
      transform: translateY(3px);
    }
  }
`
