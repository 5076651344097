import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Typography } from '../utils'

export function FormSlider({
  description = 'Wie viel soll investiert werden?',
  value,
  defaultValue = 8,
  min = 0,
  max,
  name,
  datalist,
  dispatch,
  dispatchType,
  style,
  color,
}) {
  const [defaultValueToRerender, setDefaultValueToRerender] = useState()

  useEffect(() => defaultValue && setDefaultValueToRerender(defaultValue), [])

  function handleInputChange({ target }) {
    dispatch({ type: dispatchType, value: datalist[target.value] })
  }

  return (
    <Container style={style}>
      <Label variant="label" htmlFor={name} color={color}>
        {description}
      </Label>
      <SliderValue fontWeight="medium">{value?.label}</SliderValue>
      <Slider
        color={color}
        key={defaultValueToRerender}
        id={name}
        type="range"
        name={name}
        aria-valuemin={min}
        aria-valuemax={max}
        aria-valuenow={defaultValue}
        list="tickmarks"
        min={min}
        max={max}
        step={1}
        defaultValue={defaultValue}
        onChange={handleInputChange}
      />
    </Container>
  )
}

const Container = styled.div`
  grid-column: 1 / 5;
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-rows: 1fr;
  width: 100%;
  grid-template-columns: repeat(4, minmax(4rem, 1fr));
  grid-column-gap: 1rem;

  @media (min-width: 768px) {
    grid-template-columns: repeat(4, 1fr);
    row-gap: 0.5rem;
  }
`

const Label = styled(Typography)`
  grid-column: span 3;
  color: ${({ color, theme }) => {
    if (color) return color
    else return theme.color.labelText
  }};
`
const SliderValue = styled(Typography)`
  grid-column: 4;
  font-size: 1.25rem;
  text-decoration-line: underline;
  justify-self: end;
`

const Slider = styled.input`
  grid-column: 1 / 5;
  outline: none;
  -webkit-appearance: none;
  background: ${({ color, theme }) => {
    if (color) return color
    else return `rgba(255, 255, 255, 0.7)`
  }};
  border-radius: 4px;
  width: 100%;
  margin: 1rem 0 0;
  --trackHeight: 6px;
  --trackRadius: var(--trackHeight);
  --thumbHeight: 1.5rem;
  --thumbWidth: 2.5rem;
  --thumbRadius: calc(var(--thumbHeight) / 2);
  --thumbBorderWidth: 2.5px;
  --borderColor: ${({ theme }) => theme.color.pink500};

  @media (min-width: 768px) {
    --trackHeight: 6px;
    --trackRadius: var(--trackHeight);
    --thumbHeight: 1.5rem;
    --thumbWidth: 2.5rem;
    --thumbRadius: calc(var(--thumbHeight) / 2);
    --thumbBorderWidth: 2px;
    margin: 0.5rem 0 0;
  }

  // safari 10+ only
  @media not all and (min-resolution: 0.001dpcm) {
    margin: 1rem 0 0;
  }
  // Chrome, Opera, Safari, Edge
  &::-webkit-slider-runnable-track {
    width: 100%;
    cursor: pointer;
    background: ${({ color, theme }) => {
      if (color) return color
      else return `rgba(255, 255, 255, 0.7)`
    }};

    height: var(--trackHeight);
    border-radius: var(--trackRadius);
  }
  &::-webkit-slider-thumb {
    box-sizing: border-box;
    height: var(---thumbHeight);
    width: var(-----thumbWidth);
    border-color: var(--borderColor);
    border-style: solid;
    background: white;
    cursor: pointer;
    -webkit-appearance: none;

    border-width: var(--thumbBorderWidth);
    height: var(--thumbHeight);
    width: var(--thumbWidth);
    border-radius: var(--thumbRadius);
    margin-top: -0.55rem;

    @media (min-width: 768px) {
      margin-top: -9px;
    }
  }

  //Firefox
  &::-moz-range-track {
    width: 100%;
    cursor: pointer;
    background: ${({ color, theme }) => {
      if (color) return color
      else return `rgba(255, 255, 255, 0.7)`
    }};

    height: var(--trackHeight);
    border-radius: 4px;
  }
  &::-moz-range-thumb {
    box-sizing: border-box;
    border-color: var(--borderColor);
    border-style: solid;
    background: ${({ color, theme }) => {
      if (color) return color
      else return `rgba(255, 255, 255, 0.7)`
    }};
    cursor: pointer;

    border-width: var(--thumbBorderWidth);
    height: var(--thumbHeight);
    width: var(--thumbWidth);
    border-radius: 20px;
  }
  &::-moz-focus-outer {
    border: none;
  }

  // edge
  &::-ms-track {
    width: 100%;
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    color: transparent;

    height: var(--trackHeight);
    padding: 4px;
  }
  &::-ms-fill-lower {
    background: ${({ color, theme }) => {
      if (color) return color
      else return `rgba(255, 255, 255, 0.7)`
    }};
    border-radius: 10px;
  }
  &::-ms-fill-upper {
    background: ${({ color, theme }) => {
      if (color) return color
      else return `rgba(255, 255, 255, 0.7)`
    }};
    border-radius: 10px;
  }
  &::-ms-thumb {
    box-sizing: border-box;
    border-color: var(--borderColor);
    border-style: solid;
    background: white;
    cursor: pointer;

    border-width: var(--thumbBorderWidth);
    height: var(--thumbHeight);
    width: var(--thumbWidth);
    border-radius: var(--thumbRadius);
    transform: translateY(4px);
  }
  &:focus::-ms-fill-lower {
    background: white;
  }
  &:focus::-ms-fill-upper {
    background: white;
  }
`
