import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import { Dots, LogoCircle, Typography } from '../utils'
import useMedia from 'use-media'

export const Loader = () => {
  const [height, setHeight] = useState(0)
  const isLargeScreen = useMedia({ minWidth: 768 })
  useEffect(() => {
    let vh = window.innerHeight * 0.01
    setHeight(vh)
    document.documentElement.style.setProperty('--vh', `${vh}px`)
  }, [])

  return (
    <Container height={height}>
      <Wrapper
        initial={{ scale: 1 }}
        animate={{ scale: 1.5 }}
        transition={{
          duration: 2,
          ease: 'easeInOut',
          repeat: Infinity,
          repeatType: 'reverse',
        }}
      >
        <LogoCircle />
      </Wrapper>
      <Typography variant="h4">
        Der Computer wird gerade zusammengestellt
        {isLargeScreen && <Dots />}
      </Typography>
    </Container>
  )
}

const Container = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  height: ${({ height }) => `calc(${height}px * 100)}`};
  text-align: center;
  h4 {
    margin: 2rem 1rem;
    @media (min-width: 768px) {
      display: inline-flex;
      align-items: baseline;
      justify-content: center;
      justify-content: center;
    }
  }
`
const Wrapper = styled(motion.div)`
  width: 4rem;
  @media (min-width: 768px) {
    width: 6rem;
  }
`
