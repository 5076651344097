import React from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import { Typography } from '../utils'

export function FormLabel({
  children,
  htmlFor,
  gridPos = 'span 2',
  large = false,
  color = '',
  ...rest
}) {
  return (
    <Label
      variant="label"
      htmlFor={htmlFor}
      gridPos={gridPos}
      isLarge={large}
      color={color}
      {...rest}
    >
      {children}
    </Label>
  )
}

const Label = styled(motion(Typography))`
  display: block;
  grid-column: ${({ gridPos }) => (gridPos ? `${gridPos}` : 'initial')};
  font-size: ${({ isLarge }) => (isLarge ? `2rem` : 'initial')};
  color: ${({ color, isLarge }) => {
    if (color) {
      return color
    } else {
      return isLarge ? `rgba(255, 255, 255, 1)` : 'rgba(255, 255, 255, 0.7)'
    }
  }};
`
