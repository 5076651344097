import React, { useState, useEffect } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import styled from 'styled-components'
import { ChevronUp } from 'react-feather'
import useMedia from 'use-media'
import { IconButton, Typography, PortableText } from '../utils'

export function FormInfoCard({ data, className, builderType }) {
  const [isOpen, toggleOpen] = useState(true)
  const isLargeScreen = useMedia({ minWidth: 1280 })

  useEffect(() => {
    if (isLargeScreen) {
      toggleOpen(true)
    }
  }, [isLargeScreen])

  return (
    <Container className={className}>
      {/* <Head>
        <Typography variant="h2">Hilfe zum Builder</Typography>
        {!isLargeScreen && (
          <IconButton
            variant="text"
            onClick={() => toggleOpen(!isOpen)}
            whileTap={{ scale: 0.9375 }}
          >
            <HeadIcon
              size={32}
              color="white"
              animate={{
                rotate: isOpen ? 0 : 180,
              }}
            />
          </IconButton>
        )}
      </Head>
      <AnimatePresence> */}
      <List style={{ margin: 0 }}>
        {isOpen &&
          data
            .filter((item) => item.isVisible)
            .map((item) =>
              builderType === 'quickBuilder' && !item.isQuickBuilder ? (
                <Item
                  key={item._key}
                  heading={item.heading}
                  body={item.helperText[0]}
                  isOpen={isOpen}
                  isLargeScreen={isLargeScreen}
                />
              ) : null
            )}
      </List>
      {/* </AnimatePresence> */}
    </Container>
  )
}

function ItemList() {
  if (builderType === 'quickBuilder') {
  }
}

function Item({ heading, body, isLargeScreen }) {
  return (
    <ItemContainer
      initial={{ opacity: isLargeScreen ? 1 : 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      layout
    >
      <Typography variant="h4">{heading}</Typography>
      <PortableText blocks={body} />
    </ItemContainer>
  )
}

const Container = styled.section`
  align-self: center;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px 24px 24px;
  width: 100%;
  max-width: 420px;

  @media (min-width: 1280px) {
    justify-self: start;
    padding-top: 0.5rem;
  }
`

const Head = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 0;
`

const HeadIcon = motion(ChevronUp)

const List = styled.ul`
  @media (min-width: 1280px) {
    max-height: 660px;
    overflow-y: auto;
  }
`

const ItemContainer = styled.li`
  display: flex;
  flex-direction: column;
  margin: 0 0 1rem;
  list-style: none;
  transform-origin: 50% 50%;
  h4 {
    margin-bottom: 0.25rem;
    font-size: 1.125rem;
  }
`
