import React, { useState, useEffect } from 'react'
import { navigate } from 'gatsby'
import { motion } from 'framer-motion'
import styled from 'styled-components'
import Cookie from 'universal-cookie'
import { QuickBuilderForm } from './QuickBuilderForm'
import { ProBuilderForm } from './ProBuilderForm'
import { FormInfoCard } from './FormInfoCard'
import { Loader } from '../Loader/Loader'
import { useQuickBuilderState, loadBuilderCookies, callBuild } from '../helpers'
import { ButtonGroup } from '../utils'

const cookie = new Cookie()

const setBuilderCookie = (userdata) => {
  if (cookie.get('isCookieAccepted')) {
    cookie.set('userdata', userdata, { path: '/', expires: 0 })
  }
}

export default function Builder({ builderHelp }) {
  const [isLoading, setLoading] = useState(false)
  const [builderType, setBuilderType] = useState('quickBuilder')

  const [quickBuilderState, quickBuilderDispatch] = useQuickBuilderState()

  /**
   * Quick Builder
   * handle 500 with choosing new budget
   * handle timeout with something went wrong
   */
  useEffect(() => {
    const cookieState = cookie.get('userdata')
    if (cookie.get('isCookieAccepted') && cookieState) {
      loadBuilderCookies(cookieState, quickBuilderDispatch)
    }
  }, [quickBuilderDispatch])

  const handleQuickBuilderSubmit = async (e) => {
    e.preventDefault()
    setBuilderCookie(quickBuilderState)
    setLoading(true)
    if (
      quickBuilderState.budget === '400' ||
      (quickBuilderState.budget === '450' && quickBuilderState.isCase)
    ) {
      return navigate('/low-budget/')
    }
    const options = {
      budget: quickBuilderState.budget.value,
      selectedProcessor: quickBuilderState.cpu,
      selectedGraphicscard: quickBuilderState.gpu,
      isCase: quickBuilderState.isCase,
      isPlaceholder: quickBuilderState.isPlaceholder,
      provider: quickBuilderState.provider.value,
    }

    try {
      const { build, buildMetadata } = await callBuild(options)
      navigate(`/build/${buildMetadata.buildID}`, {
        state: {
          buildMetadata: buildMetadata,
          buildData: build,
          ...quickBuilderState,
        },
      })
    } catch (error) {
      console.log(error.status)
      navigate('/something-went-wrong')
    }
  }

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <Wrapper>
          <ProBackground
            initial={{ opacity: 0 }}
            animate={{ opacity: builderType === 'quickBuilder' ? 0 : 1 }}
            transition={{
              duration: 1.33,
              ease: 'easeOut',
            }}
          />
          <Container builderType={builderType}>
            <SwtichContainer builderType={builderType}>
              <ButtonGroup
                labelId="BtnGroupLarge-Builder-Type"
                onChange={(e) => setBuilderType(e.target.value)}
                value={builderType}
                defaultOption={builderType}
                options={[
                  {
                    label: 'Quick Builder',
                    value: 'quickBuilder',
                  },
                  {
                    label: 'Pro Builder',
                    value: 'proBuilder',
                  },
                ]}
                name="builder-type"
              />
            </SwtichContainer>
            {builderType === 'quickBuilder' ? (
              <>
                <QuickBuilderForm
                  budget={quickBuilderState.budget}
                  cpu={quickBuilderState.cpu}
                  gpu={quickBuilderState.gpu}
                  provider={quickBuilderState.provider}
                  isCase={quickBuilderState.isCase}
                  isPlaceholder={quickBuilderState.isPlaceholder}
                  dispatch={quickBuilderDispatch}
                  handleSubmit={handleQuickBuilderSubmit}
                />
                <FormInfoCard data={builderHelp} className="formInfoCard" />
              </>
            ) : (
              <ProBuilderForm />
            )}
          </Container>
        </Wrapper>
      )}
    </>
  )
}

const Wrapper = styled(motion.div)`
  min-height: 100vh;
  height: 100%;
  width: 100%;
  position: relative;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-block: 3rem 5rem;
  /* z-index: 20; */

  background: linear-gradient(
      112.97deg,
      #0e99d4 5.81%,
      rgba(14, 153, 212, 0) 38.8%
    ),
    radial-gradient(
      29.76% 49.52% at 88.92% 92.72%,
      #ffb659 11.46%,
      rgba(232, 122, 94, 0) 100%
    ),
    linear-gradient(180deg, #4b368b 0%, #e8619c 100%);

  @media (min-width: 768px) {
    padding-top: 80px;
  }

  @media (min-width: 960px) {
    background: radial-gradient(
        21.45% 42.43% at 29.93% 43.99%,
        #583fa0 0%,
        rgba(115, 89, 191, 0) 100%
      ),
      linear-gradient(112.97deg, #0e99d4 5.81%, rgba(14, 153, 212, 0) 38.8%),
      radial-gradient(
        29.76% 49.52% at 88.92% 92.72%,
        #ffb659 11.46%,
        rgba(232, 122, 94, 0) 100%
      ),
      linear-gradient(180deg, #4b368b 0%, #e8619c 100%);
  }
`

const ProBackground = styled(motion.div)`
  z-index: 1;

  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  background: radial-gradient(
      75.17% 64.09% at 94.58% 104.97%,
      rgba(226, 53, 128, 0.79) 0%,
      rgba(78, 33, 52, 0) 100%
    ),
    radial-gradient(
      41.96% 50.66% at 27.88% 33.85%,
      #4b368b 0%,
      rgba(75, 54, 139, 0) 100%,
      rgba(28, 20, 51, 0) 100%
    ),
    linear-gradient(113.93deg, #4658ff 2.54%, rgba(70, 88, 255, 0) 35.48%),
    #140e25;
`

const Container = styled.div`
  z-index: 2;

  position: relative;
  margin: 2rem 1rem 0;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  justify-items: center;
  row-gap: 2rem;
  form {
    grid-row: 2;
  }
  .formInfoCard {
    grid-row: 3;
  }
  @media (min-width: 768px) {
    margin: 2rem 2rem 0;
    /* margin-top: 6rem; */
  }
  /* @media (min-width: 960px) {
    margin: 6rem 2rem 0;
  } */
  @media (min-width: 1280px) {
    margin: 2rem 0 0;
    padding: 0 2rem;
    grid-template-columns: ${({ builderType }) =>
      builderType === 'quickBuilder' ? '4fr 3fr' : '1fr'};
    column-gap: 4rem;
    /* margin-top: 8rem; */
    .formInfoCard {
      grid-row: 2;
    }
  }
`

const SwtichContainer = styled.div`
  grid-column: 1;
  grid-row: 1;
  width: 100%;
  max-width: 672px;
  @media (min-width: 1280px) {
    grid-column: ${({ builderType }) =>
      builderType === 'quickBuilder' ? '1 / 3' : '1'};
  }
`
